import { useRegion } from "@with-nx/region";
import { ReusableSection, Rule } from "@with-nx/simple-ui/atoms";
import { CatalogLayout } from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { PopupModal as PopupModalCalendly } from "react-calendly";
import { Box } from "simple-effing-primitive-layout";

import { RegisterForm } from "../../components/w/auth/RegisterForm";
import { SignInFormContainer } from "../../components/w/auth/SignInFormContainer";
import { useAuthForm } from "../../components/w/auth/useAuthForm";

export default function Page({ resolvedUrl }) {
  const { form, _form, _shouldNotRedirectLogin } = useAuthForm();
  const region = useRegion();

  const [element, _element] = useState<HTMLElement | null>(null);

  useEffect(() => {
    _shouldNotRedirectLogin(false);
    _element(document.getElementById("__next"));

    const params = new URLSearchParams(window.location.search);
    const form = params.get("form");

    if (form) {
      _form(form as "login" | "register" | "forgot");
    }
  }, []);

  const FormHeader = () => {
    if (form === "login") {
      return (
        <Box css="d:flex a:center j:space-between" parse="mb:24">
          <Rule rule="ll" color="var(--primary)">
            Sign In
          </Rule>

          <Rule
            rule="ls"
            color="var(--font3)"
            style={{
              textDecoration: "underline",
            }}
            press={() => _form("register")}
            native={{
              cypress: "label-sign-up",
            }}
          >
            or create an account
          </Rule>
        </Box>
      );
    }
  };

  const AuthForm = () => {
    if (form === "register") {
      return (
        <>
          <Box css="d:flex a:center j:space-between" parse="mb:24">
            <Rule rule="ll" color="var(--primary)">
              Create Account
            </Rule>
            <Rule
              rule="ls"
              color="var(--font3)"
              style={{
                textDecoration: "underline",
              }}
              press={() => _form("login")}
              native={{
                cypress: "label-sign-in",
              }}
            >
              or sign in
            </Rule>
          </Box>

          <RegisterForm />
        </>
      );
    }

    return (
      <>
        <FormHeader />
        <SignInFormContainer form={form} _form={_form} />
      </>
    );
  };

  return (
    <CatalogLayout
      footerNoAngle
      hideAuthButtons
      title="Broadway Media - Login / Register"
      resolvedUrl={resolvedUrl}
    >
      <ReusableSection>
        <div className="container" style={{ position: "relative", zIndex: 1 }}>
          <Row gutter={[36, 18]} align="middle" justify="center">
            <Col xs={24} sm={24} md={18} lg={16} xl={10}>
              <AuthForm />
            </Col>
          </Row>
        </div>
      </ReusableSection>
    </CatalogLayout>
  );
}

export const getServerSideProps = async ({ resolvedUrl }) => {
  return {
    props: {
      resolvedUrl,
    },
  };
};
